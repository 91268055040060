/*  ------------------------------------------------ GLOBAL ------------------------------------------------ */

@font-face {
  font-family: 'galhau';
  src: url('../public/fonts/Galhau_Regular.ttf');
}

*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root, .wrapper, div, .canvas, .canvas__item{
  height: 100%;
  width: 100%;
}

.canvas__item div:nth-child(2){
  transform: none !important;
  top: 0;
}

html{
  background: #000;
}

.canvas__item{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.html{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
}

p {
  color: #fff;
  font-family: 'galhau';
  letter-spacing: 0.5rem;
  font-size: 1.5rem;
  text-align: center;
}

@media screen and (min-width: 1024px) {

  p {
    font-size: 3rem;
  }

}